// Here you can add other styles

.table .form-control-sm {
  background: rgba(237, 240, 235, 0.85);
}

.pdfList {
  font-size: 1.25rem;
  color: #008752;
  font-weight: bold;
  border-color: #008752;
}

.dropdown-divider {
  border-color: #008752;
}

.box-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #008752;
  border-color: #008752;
}

.admin-table .table thead th {
  border-top: transparent;
}

.dashboard-table .table thead th {
  border-top: transparent;
}

.dashboard-table .table td {
  border-top: transparent;
}

.dashboard-table .table td.p-0 {
  border-top: 2px solid #d8dbe0;
}

.dashboard-table .page-item.active .page-link {
  background-color: #008752;
  border-color: #008752;
}

.view-button {
  color: white;
  background-color: #008752;
}
